import StorySummary from '../models/StorySummary';
import Story from '../models/Story';

const getBaseUrl = (): string => {
  if (process.env.NODE_ENV === 'development') {
    return 'http://localhost:4000/web/hn';
  }
  return 'https://hn.nextabit.com/web/hn'
}

const APIS = {
  SUMMARIES: '/summaries',
  DETAILS: '/stories',
};

const getSummaries = (ids: number[]): Promise<StorySummary[]> => {
  return new Promise((res, rej) => {
    let query = '?ids=';
    for (let i = 0; i < ids.length - 1; i++) {
      query += `${ids[i]}&ids=`;
    }
    query += `${ids[ids.length - 1]}`;

    const url = `${getBaseUrl()}${APIS.SUMMARIES}${query}`;
    fetch(url).then(response => response.json()).then((data: { stories: any[] }) => {
      const stories = data.stories.map(item => new StorySummary(item));
      res(stories);
    }).catch(err => {
      console.error('err', err);
    });
  });
}

const getStoryDetail = (id: number): Promise<Story> => {
  return new Promise((res, rej) => {
    const url = `${getBaseUrl()}${APIS.DETAILS}?ids=${id}`;
    fetch(url).then(response => response.json()).then((data: { stories: any[] }) => {
      const stories = data.stories.map(item => new Story(item));
      res(stories[0]);
    }).catch(err => {
      console.error('err', err);
    });
  });
}

export {
  getSummaries,
  getStoryDetail,
};
