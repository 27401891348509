import React from 'react';
import { Route, Switch } from 'react-router-dom';
import './App.css';
import AsyncComponent from './components/AsyncComponent';

const AsyncHome = AsyncComponent(() => import('./pages/Home'));
const AsyncStoryDetailPage = AsyncComponent(() => import('./pages/StoryDetail'));
const AsyncTestPage = AsyncComponent(() => import('./pages/Test'));

const withTracker = (Component: React.ComponentType<any>) => {
  return class WithTracker extends React.Component {
    componentDidMount() {
      if (process.env.NODE_ENV !== 'development') {
        (window as any).Countly.q.push(['track_sessions']);
        (window as any).Countly.q.push(['track_pageview']);
      }
    }

    render() {
      return <Component {...this.props} />;
    }
  };
};

const App: React.FC = () => {
  return (
    <>
      <div>
        <Switch>
          <Route
            exact
            path='/'
            component={withTracker(AsyncHome)}
          />
          <Route
            exact
            path='/index.html'
            component={withTracker(AsyncHome)}
          />
          <Route
            exact
            path='/item'
            component={withTracker(AsyncStoryDetailPage)}
          />
          <Route
            exact
            path='/test'
            component={AsyncTestPage}
          />
        </Switch>
      </div>

    </>
  );
}

export default App;
