export const actions = {
  TOGGLE_DARK_MODE: 'TOGGLE_DARK_MODE',
  TOGGLE_STORY_LIST_TYPE: 'TOGGLE_STORY_LIST_TYPE',
  MARK_STORY_READ: 'MARK_STORY_READ',
}

export const toggleDarkMode = () => (dispatch: Function) => {
  dispatch({
    type: actions.TOGGLE_DARK_MODE
  });
}

export const toggleStoryListMode = () => (dispatch: Function) => {
  dispatch({
    type: actions.TOGGLE_STORY_LIST_TYPE
  });
}

export const markStoryRead = (id: number) => (dispatch: Function) => {
  dispatch({
    type: actions.MARK_STORY_READ,
    payload: { id }
  });
}