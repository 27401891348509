import { actions as RefreshActions } from '../actions/refreshStories';
import { actions as TopStoryIdActions } from '../actions/fetchTopStoryIds';

const initialState = {
  isFetching: false,
  isFetched: false,
  isFetchError: false,
  ids: [] as number[],
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case RefreshActions.REFRESH_STORIES:
      return {
        ...initialState
      };
    case TopStoryIdActions.FETCH_TOP_STORY_IDS:
      return {
        ...state,
        isFetching: true,
        isFetched: false,
        isFetchError: false,
      }

    case TopStoryIdActions.FETCH_TOP_STORY_IDS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        isFetchError: false,
        ids: action.payload.ids
      }

    case TopStoryIdActions.FETCH_TOP_STORY_IDS_FAIL:
      return {
        ...state,
        isFetching: false,
        isFetched: false,
        isFetchError: true,
      }

    default:
      return state
  }
}
