import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import app from './appReducer';
import summaries from './summariesReducer';
import topStories from './topStoriesReducer';

const appPersistConfig = {
  key: 'app',
  storage: storage,
}

export default combineReducers({
  app: persistReducer(appPersistConfig, app),
  summaries,
  topStories
});
