class StorySummary {
  public id: number;
  public authors: string[];
  public by: string;
  public time: Date;
  public title: string;
  public type: string;
  public summary: string;

  constructor(raw: any) {
    this.id = raw.id;
    this.authors = raw.authors;
    this.by = raw.by;
    this.time = new Date(raw.time);
    this.title = raw.title;
    this.type = raw.type;
    this.summary = raw.summary;
  }
}

export default StorySummary;
