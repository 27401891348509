import { getSummaries } from '../apis/nextabit-hn-api';

export const actions = {
  FETCH_STORY_SUMMARIES: 'FETCH_STORY_SUMMARIES',
  FETCH_STORY_SUMMARIES_SUCCESS: 'FETCH_STORY_SUMMARIES_SUCCESS',
  FETCH_STORY_SUMMARIES_FAIL: 'FETCH_STORY_SUMMARIES_FAIL'
}

const PAGE_SIZE = 10;

export const fetchSummaries = (ids: number[], currentPage: number) => (dispatch: Function) => {
  const currentStart = Math.min(PAGE_SIZE * currentPage, ids.length);
  const currentEnd = Math.min(PAGE_SIZE * (currentPage + 1), ids.length);
  if (currentStart !== currentEnd) {
    const idsToFetch = ids.slice(currentStart, currentEnd)
    dispatch({
      type: actions.FETCH_STORY_SUMMARIES,
      payload: { idsToFetch }
    });
    getSummaries(idsToFetch).then(stories => {
      dispatch({
        type: actions.FETCH_STORY_SUMMARIES_SUCCESS,
        payload: { stories }
      });
    }).catch(err => {
      dispatch({
        type: actions.FETCH_STORY_SUMMARIES_FAIL,
      });
    });
  }
}
