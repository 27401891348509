import { actions as AppStateActions } from '../actions/appStateActions';
import { STORY_LIST_TYPE } from '../components/StoryListItem';

const MAX_HISTORY = 200;

const isDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches
const isLightMode = window.matchMedia("(prefers-color-scheme: light)").matches
const isNotSpecified = window.matchMedia("(prefers-color-scheme: no-preference)").matches
const hasNoSupport = !isDarkMode && !isLightMode && !isNotSpecified;

const initialState = {
  viewMode: STORY_LIST_TYPE.COMFORTABLE,
  isDarkMode: hasNoSupport ? false : isNotSpecified ? false : isDarkMode,
  readIds: [] as number[],
}

export default (state = initialState, action: any) => {
  switch (action.type) {

    case AppStateActions.TOGGLE_DARK_MODE:
      return {
        ...state,
        isDarkMode: !state.isDarkMode
      };

    case AppStateActions.TOGGLE_STORY_LIST_TYPE:
      let viewMode;
      if (state.viewMode === STORY_LIST_TYPE.COMFORTABLE) {
        viewMode = STORY_LIST_TYPE.COZY;
      } else if (state.viewMode === STORY_LIST_TYPE.COZY) {
        viewMode = STORY_LIST_TYPE.COMPACT;
      } else {
        viewMode = STORY_LIST_TYPE.COMFORTABLE;
      }

      return {
        ...state,
        viewMode: viewMode
      };

    case AppStateActions.MARK_STORY_READ:
      let readIds = [...state.readIds];
      if (readIds.indexOf(action.payload.id) < 0) {
        if (state.readIds.length >= MAX_HISTORY) {
          readIds.shift();
        }
        readIds.push(action.payload.id as number);
      }

      return {
        ...state,
        readIds
      };

    default:
      return state
  }
}
