import React, { Component } from 'react';
import './StoryListItem.css';
import StorySummary from '../../models/StorySummary';

export enum STORY_LIST_TYPE {
  COMFORTABLE = 'comfortable',
  COMPACT = 'compact',
  COZY = 'cozy',
}

interface StoryListItemProps {
  story: StorySummary;
  isRead?: boolean;
  isDark?: boolean;
  listType?: STORY_LIST_TYPE
}

const getAgoString = (date: Date): string => {
  const now = Math.floor((new Date()).getTime() / 1000);
  const dateStamp = date.getTime();

  let diff = Math.floor(now - dateStamp);
  if (diff < 60) {
    return `${diff}s ago`;
  }

  diff = Math.floor(diff / 60);
  if (diff < 60) {
    return `${diff}m ago`;
  }
  diff = Math.floor(diff / 60);
  if (diff < 24) {
    return `${diff}h ago`;
  }

  diff = Math.floor(diff / 24);
  return `${diff}d ago`;
}

class StoryListItem extends Component<StoryListItemProps> {
  render() {
    let listType = STORY_LIST_TYPE.COMFORTABLE;
    if (this.props.listType) {
      listType = this.props.listType;
    }

    let modifier = '';
    if (this.props.isDark === true) {
      modifier += ' dark';
    }
    if (this.props.isRead === true) {
      modifier += ' read';
    }

    return (
      <>
        {listType !== STORY_LIST_TYPE.COMPACT &&
          <div className={'story-title' + modifier}>
            {this.props.story.title}
          </div>
        }
        {listType === STORY_LIST_TYPE.COMPACT &&
          <div className={'story-title' + modifier}
            style={{
              whiteSpace: 'nowrap',
              overflow: 'auto',
              marginBottom: '24px'
            }}
          >
            {this.props.story.title}
          </div>
        }

        {listType === STORY_LIST_TYPE.COMFORTABLE &&
          <div className={'story-byline' + modifier}>
            {this.props.story.by}, {getAgoString(this.props.story.time)}
          </div>
        }
        {listType === STORY_LIST_TYPE.COZY &&
          <div className={'story-byline' + modifier}
            style={{ marginBottom: '30px' }}
          >
            {this.props.story.by}, {getAgoString(this.props.story.time)}
          </div>
        }

        {listType === STORY_LIST_TYPE.COMFORTABLE &&
          <div className={'truncate-overflow story-summary' + modifier}>
            {this.props.story.summary}
          </div>
        }
      </>
    );
  }
}

export default StoryListItem;
