import { actions as SummariesActions } from '../actions/fetchSummaries';
import { actions as RefreshActions } from '../actions/refreshStories';
import StorySummary from '../models/StorySummary';

const initialState = {
  isFetching: false,
  isFetched: false,
  isFetchError: false,
  currentPage: 0,
  stories: [] as StorySummary[]
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case RefreshActions.REFRESH_STORIES:
      return {
        ...initialState
      };
    case SummariesActions.FETCH_STORY_SUMMARIES:
      return {
        ...state,
        isFetching: true,
        isFetched: false,
        isFetchError: false,
      }

    case SummariesActions.FETCH_STORY_SUMMARIES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        isFetchError: false,
        currentPage: state.currentPage + 1,
        stories: [...state.stories, ...action.payload.stories]
      }

    case SummariesActions.FETCH_STORY_SUMMARIES_FAIL:
      return {
        ...state,
        isFetching: false,
        isFetched: false,
        isFetchError: true,
      }

    default:
      return state
  }
}
