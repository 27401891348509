import { getTopStoryIds } from '../apis/firebase-hn-api';

export const actions = {
  FETCH_TOP_STORY_IDS: 'FETCH_TOP_STORY_IDS',
  FETCH_TOP_STORY_IDS_SUCCESS: 'FETCH_TOP_STORY_IDS_SUCCESS',
  FETCH_TOP_STORY_IDS_FAIL: 'FETCH_TOP_STORY_IDS_FAIL'
}

export const fetchTopStoryIds = () => (dispatch: Function) => {
  dispatch({
    type: actions.FETCH_TOP_STORY_IDS,
  });
  getTopStoryIds().then(ids => {
    dispatch({
      type: actions.FETCH_TOP_STORY_IDS_SUCCESS,
      payload: { ids }
    });
  }).catch(err => {
    dispatch({
      type: actions.FETCH_TOP_STORY_IDS_FAIL,
    });
  });
}
