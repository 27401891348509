const getBaseUrl = (): string => {
  return 'https://hacker-news.firebaseio.com/v0';
}

const APIS = {
  TOP_STORIES: '/topstories.json',
};

const getTopStoryIds = (): Promise<number[]> => {
  return new Promise((res, rej) => {
    const url = `${getBaseUrl()}${APIS.TOP_STORIES}`;
    fetch(url).then(response => response.json()).then(data => {
      res(data);
    }).catch(err => {
      console.error('err', err);
    });
  });
}

export {
  getTopStoryIds
};
